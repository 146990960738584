exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-404-js": () => import("./../../../src/pages/de/404.js" /* webpackChunkName: "component---src-pages-de-404-js" */),
  "component---src-pages-es-404-js": () => import("./../../../src/pages/es/404.js" /* webpackChunkName: "component---src-pages-es-404-js" */),
  "component---src-pages-fr-404-js": () => import("./../../../src/pages/fr/404.js" /* webpackChunkName: "component---src-pages-fr-404-js" */),
  "component---src-templates-article-article-js": () => import("./../../../src/templates/Article/Article.js" /* webpackChunkName: "component---src-templates-article-article-js" */),
  "component---src-templates-blog-blog-js": () => import("./../../../src/templates/Blog/Blog.js" /* webpackChunkName: "component---src-templates-blog-blog-js" */),
  "component---src-templates-category-category-js": () => import("./../../../src/templates/Category/Category.js" /* webpackChunkName: "component---src-templates-category-category-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/Page/Page.js" /* webpackChunkName: "component---src-templates-page-page-js" */)
}

